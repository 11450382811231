import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators  } from '@angular/forms';
import { RequestService } from '../../services/request.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ConfigService } from '../../services/config.service';
import { ResponseMessage } from '../../models/Common/ResponseMessage';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.less']
})
export class ActivationComponent implements OnInit {

  formData = {code: '', captcha: '', formID: this.configService.newGuid() };
  activationForm: FormGroup;

  activationCode: string = null;

  img64: any = '';
  get code() { return this.activationForm.get('code'); }
  get captcha() { return this.activationForm.get('captcha'); }
  get formID() { return this.activationForm.get('formID'); }

   constructor(private toastr: ToastrService, private request: RequestService, private configService: ConfigService,
    private _sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.activationForm = new FormGroup({
      'code': new FormControl(this.formData.code, [
        Validators.required,
        Validators.minLength(35),
        Validators.maxLength(50)
      ]),
      'captcha': new FormControl(this.formData.captcha, [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(8)
      ]),
      'formID': new FormControl(this.formData.formID)
    }, { updateOn: 'change' });

    this.refreshCaptcha();
  }

  refreshCaptcha() {
    this.request.HttpGet<any>(`api/image/captcha/${this.formData.formID}`).subscribe((data: any) => {
      this.img64 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64, ' + data);
    });
  }

  onCaptchaRefresh(e) {
    e.preventDefault();
    this.refreshCaptcha();
  }

  onSubmit() {
    this.request
      .HttpPost<ResponseMessage>('publisher/activation', this.activationForm.value, false)
      .subscribe(r => {
        if ((!r.messages || !r.messages.length) &&  r.result)  {
          this.activationCode = r.result;
          this.activationForm.reset();
          this.toastr.success('Код активації успішно отримано!', 'Успішно виконано!', {extendedTimeOut: 3000});
          this.refreshCaptcha();

          return;
        }

        this.toastr.error(r.messages[0], 'Помилка активації!');
      });
  }
}
