import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild  } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { SanitizeHtmlPipe } from '../../pipes/sanitize-html-pipe';
import { AppService } from '../../services/app.service';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})

export class OrderComponent implements OnInit {
  @ViewChild('detailsModal') detailsModal;

  apps: Array<any> = null;
  appDetails: any = {};

  constructor(private appService: AppService, private orderService: OrderService, private route: ActivatedRoute) {

  }

  ngOnInit() {
    const showId = this.route.snapshot.paramMap.get('showId');
    const orderObservable = this.orderService.GetOrder(showId);

    if (orderObservable == null) {
      this.apps = [];
    } else {
      orderObservable.subscribe((data: any[]) => {
        this.apps = data;
      }, error => this.apps = []);
    }
  }

  showDetails(e: any, appId: number): void {
    e.preventDefault();

    const app = this.apps.find(m => m.id === appId);
    if (app != null) {
      this.appDetails = JSON.parse(JSON.stringify(app));
    }

    this.appService.appDetails(appId).subscribe(details => {
      if (details) {
        this.appDetails.coverUrl = details.coverUrl;
        this.appDetails.htmlDescription = details.htmlDescription;
      }
    });

    this.detailsModal.show();
  }
}
