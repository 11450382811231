import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators  } from '@angular/forms';
import { RequestService } from '../../services/request.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ConfigService } from '../../services/config.service';
import { ResponseMessage } from '../../models/Common/ResponseMessage';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.less']
})
export class ContactsComponent implements OnInit {

  formData = {name: '', phone: '', email: '', message: '', captcha: '', formID: this.configService.newGuid() };
  contactForm: FormGroup;

  img64: any = '';
  get name() { return this.contactForm.get('name'); }
  get phone() { return this.contactForm.get('phone'); }
  get email() { return this.contactForm.get('email'); }
  get message() { return this.contactForm.get('message'); }
  get captcha() { return this.contactForm.get('captcha'); }
  get formID() { return this.contactForm.get('formID'); }

   constructor(private toastr: ToastrService, private request: RequestService, private configService: ConfigService,
    private _sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.contactForm = new FormGroup({
      'name': new FormControl(this.formData.name, [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(100)
      ]),
      'phone': new FormControl(this.formData.phone, [
        Validators.required,
        Validators.pattern('[0-9 +()\-]*'),
        Validators.minLength(10),
        Validators.maxLength(20)
      ]),
      'email': new FormControl(this.formData.email, [
        Validators.required,
        Validators.email,
        Validators.minLength(4),
        Validators.maxLength(100)
      ]),
      'message': new FormControl(this.formData.message, [
        Validators.required,
        Validators.minLength(20),
        Validators.maxLength(1000)
      ]),
      'captcha': new FormControl(this.formData.captcha, [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(8)
      ]),
      'formID': new FormControl(this.formData.formID)
    }, { updateOn: 'change' });

    this.refreshCaptcha();
  }

  refreshCaptcha() {
    this.request.HttpGet<any>(`api/image/captcha/${this.formData.formID}`).subscribe((data: any) => {
      this.img64 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64, ' + data);
    });
  }

  onCaptchaRefresh(e) {
    e.preventDefault();
    this.refreshCaptcha();
  }

  onSubmit() {
    this.request
      .HttpPost<ResponseMessage>('api/message/contact', this.contactForm.value, true)
      .subscribe(r => {
        if (!r.messages || !r.messages.length) {
          this.contactForm.reset();
          this.toastr.success('Ваше повідомлення відправлено!', 'Успішно виконано!', {extendedTimeOut: 3000});
          this.refreshCaptcha();

          return;
        }

        this.toastr.error(r.messages[0], 'Помилка відправлення!');
      });
  }
}
