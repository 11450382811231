import { Injectable, Injector } from '@angular/core';
import { Http, Response, Headers, URLSearchParams, RequestOptions, Request } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { ConfigService } from './config.service';
import { Router } from '@angular/router';
import { SessionService } from './session.service';

@Injectable()
export class RequestService {
  private ConnectionTestSubject = new Subject<boolean>();
  private ConnectionTestSent = false;
  // errorManager: ErrorManagerService;

  constructor(private http: HttpClient,
    private sessionManager: SessionService,
    private config: ConfigService,
    private injector: Injector,
    private router: Router) { }

  public GetConnectionTestResult(): Observable<boolean> {
    return this.ConnectionTestSubject.asObservable();
  }

  public HttpGet<T>(path: string, params: Object = null): Observable<T> {
    const options: any = this.prepareRequestOptions('application/json', params);

    const pathSplit = path.split(':');
    if (!pathSplit || !pathSplit.length || pathSplit[0].toLowerCase() !== 'http' && pathSplit[0].toLowerCase() !== 'https') {
      path = this.config.apiUrl + '/' + path;
    }

    return this.http.get(path, options).pipe(
      map((resp: any) => {
        if (resp) {
          return resp;
        }

        let result: any;

        try {
          result = resp.json();
        } catch (e) {
          result = (resp as any)._body;
        }

        if (result && result.errorCode === 500) {
          this.logError();
        }
        return result as T;
      }),
      catchError(this.handleError));
  }

  public HttpPostFileUpload<T>(path: string, fileField: any = null, fileName: any = 'file', formData: FormData = null): Observable<T> {
    if (!formData && !fileField) {
      return null;
    }

    if (!formData) {
      formData = new FormData();
    }

    if (fileField) {
      formData.append(fileName, fileField);
    }

    const pathSplit = path.split(':');
    if (!pathSplit || !pathSplit.length || pathSplit[0].toLowerCase() !== 'http' && pathSplit[0].toLowerCase() !== 'https') {
      path = this.config.apiUrl + '/' + path;
    }

    return this.http.post(path, formData).pipe(
      map((resp: Response) => {
        const result = resp.json();
        if (result && result.errorCode === 500) {
          this.logError();
        }
        return result as T;
      }),
      catchError(this.handleError));
  }

  public HttpPost<T>(path: string, params: Object = null, asJson: Boolean = false): Observable<T> {
    let body: String;
    let options: any;

    if (asJson) {
      body = JSON.stringify(params);
      options = this.prepareRequestOptions('application/json');
    } else {
      body = this.preparePostBody(params);
      options = this.prepareRequestOptions('application/x-www-form-urlencoded');
    }

    const pathSplit = path.split(':');
    if (!pathSplit || !pathSplit.length || pathSplit[0].toLowerCase() !== 'http' && pathSplit[0].toLowerCase() !== 'https') {
      path = this.config.apiUrl + '/' + path;
    }

    return this.http.post(path, body, options).pipe(
      map((resp: any) => {
        return resp as T;
      }),
      catchError(e => this.handleError(e, this)));
  }

  public HttpPut<T>(path: string, params: Object = null, asJson: Boolean = false): Observable<T> {
    let body: String;
    let options: any;

    if (asJson) {
      body = JSON.stringify(params);
      options = this.prepareRequestOptions('application/json');
    } else {
      body = this.preparePostBody(params);
      options = this.prepareRequestOptions('application/x-www-form-urlencoded');
    }

    const pathSplit = path.split(':');
    if (!pathSplit || !pathSplit.length || pathSplit[0].toLowerCase() !== 'http' && pathSplit[0].toLowerCase() !== 'https') {
      path = this.config.apiUrl + '/' + path;
    }

    return this.http.put(path, body, options).pipe(
      map((resp: any) => {
        return resp as T;
      }),
      catchError(e => this.handleError(e, this)));
  }

  public HttpDelete<T>(path: string, params: Object = null): Observable<T> {
    const options: any = this.prepareRequestOptions('application/json', params);

    const pathSplit = path.split(':');
    if (!pathSplit || !pathSplit.length || pathSplit[0].toLowerCase() !== 'http' && pathSplit[0].toLowerCase() !== 'https') {
      path = this.config.apiUrl + '/' + path;
    }

    return this.http.delete(path, options).pipe(
      map((resp: any) => {
        return resp as T;
      }),
      catchError(e => this.handleError(e, this)));
  }

  handleError(error, _this) {
    this.logError();

    if (error.status === 401 && _this) {
      _this.router.navigate([_this.config.signinUrl]);
      return;
    }

    return Observable.throw(error || 'Unknown error');
  }

  preparePostBody(params: Object = null): string {
    const body = [];

    if (params != null) {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          body.push(key.toString() + '=' + params[key]); // JSON.stringify
        }
      }
    }

    return body.length > 0 ? body.join('&') : '';
  }

  prepareRequestOptions(contentType: string = null, params: Object = null): any {
    const headers = new HttpHeaders();
    const options: any = { headers: headers };

    if (contentType) {
      options.headers = options.headers.append('Content-Type', contentType);
    }

    options.withCredentials = true;

    if (params != null) {
      options.search = new URLSearchParams();
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          options.search.append(key.toString(), params[key]); // JSON.stringify
        }
      }
    }

    return options;
  }

  logError(): void {
    console.log('RequestService error');
  }
}
