import { Component, Input, OnInit } from '@angular/core';
import {Router, NavigationStart} from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { BasketService } from '../services/basket.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.less']
})
export class NavMenuComponent implements OnInit {
  public navItems = [
    {
      name: 'Головна',
      url: '/home',
      icon: 'fa fa-home fa-2x'
    },
    {
      name: 'Тестування',
      url: 'http://my.ilesson.com.ua',
      icon: 'fa fa-check-square-o fa-2x'
    },
    {
      name: 'Завантажити',
      url: '/download',
      icon: 'fa fa-cloud-download fa-2x'
    },
    {
      name: 'Оплата',
      url: '/payment',
      icon: 'fa fa-credit-card fa-2x'
    },
    {
      name: 'Активація',
      url: '/activation',
      icon: 'fa fa-check fa-2x'
    },
    {
      name: 'Запитання',
      url: '/questions',
      icon: 'fa fa-question-circle fa-2x'
    },
    {
      name: 'Підтримка',
      url: '/support',
      icon: 'fa fa-support fa-2x'
    },
    {
      name: 'Контакти',
      url: '/contacts',
      icon: 'fa fa-envelope fa-2x'
    }
  ];

  isExpanded = false;
  currentYear = new Date().getFullYear();

  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;

  constructor(private toastr: ToastrService, private router: Router, public basketService: BasketService) {
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });

    this.changes.observe(<Element>this.element, {
      attributes: true
    });
  }

  ngOnInit() {
    // this.basketService.Add(1, 3);

    this.initFirstMenuItem();
  }

  initFirstMenuItem(): void {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        const mainMenuLinks = document.querySelectorAll('app-sidebar a.nav-link');
        if (mainMenuLinks && mainMenuLinks.length) {
          const firstElement = mainMenuLinks[0] as HTMLElement;

          if (val.url === '/') {
            firstElement.classList.add('active');
          } else {
            firstElement.classList.remove('active');
          }
        }
      }
    });
  }

  onBasketClick() {
    if (!this.basketService.getBasketLabel()) {
      this.toastr.error('Додайте хоча б один ключ у корзину.', 'Корзина порожня!');
      return;
    }

    this.router.navigate(['/basket']);
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

}
