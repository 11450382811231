import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppHeaderModule, AppFooterModule, AppSidebarModule } from '@coreui/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

import { AppRoutingModule } from './pages/app-routing.module';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { ConfigService } from './services/config.service';
import { RequestService } from './services/request.service';
import { SessionService } from './services/session.service';
import { BasketService } from './services/basket.service';
import { AppService } from './services/app.service';
import { OrderService } from './services/order.service';


@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppHeaderModule,
    AppFooterModule,
    AppSidebarModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    LoadingBarHttpClientModule
  ],
  providers: [ConfigService, RequestService, SessionService, BasketService, AppService, OrderService],
  bootstrap: [AppComponent]
})
export class AppModule { }
