import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';

/**
 * @description
 * @class
 */
@Injectable()
export class OrderService {

  constructor(private request: RequestService) {

  }

  public GetOrder(showId: string): Observable<any> {
    if (showId) {
      return this.request.HttpGet(`api/order/${showId}`);
    }

    return null;
  }
}
