import { Component, OnInit, ViewChild  } from '@angular/core';
import { FormControl, FormGroup, Validators  } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';

import { SanitizeHtmlPipe } from '../../pipes/sanitize-html-pipe';
import { AppService } from '../../services/app.service';
import { BasketService } from '../../services/basket.service';
import { ConfigService } from '../../services/config.service';
import { RequestService } from '../../services/request.service';
import { ResponseMessage } from '../../models/Common/ResponseMessage';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss']
})

export class BasketComponent implements OnInit {

  @ViewChild('detailsModal') detailsModal;
  @ViewChild('offerModal') offerModal;
  @ViewChild('customerModal') customerModal;

  offerDisabled = false;

  formData = {name: '', phone: '', email: '', captcha: '', formID: this.configService.newGuid() };
  customerForm: FormGroup;

  img64: any = '';

  confirmButton = true;

  get name() { return this.customerForm.get('name'); }
  get phone() { return this.customerForm.get('phone'); }
  get email() { return this.customerForm.get('email'); }
  get captcha() { return this.customerForm.get('captcha'); }
  get formID() { return this.customerForm.get('formID'); }

  apps = [];
  appDetails: any = {};

  payData = '';
  paySignature = '';

  constructor(private toastr: ToastrService, private appService: AppService,
    private configService: ConfigService, private _sanitizer: DomSanitizer,
    private request: RequestService, public basketService: BasketService) { }

  ngOnInit() {
    this.updateAppsInBasket();

    this.customerForm = new FormGroup({
      'name': new FormControl(this.formData.name, [
        Validators.maxLength(100)
      ]),
      'phone': new FormControl(this.formData.phone, [
        Validators.required,
        Validators.pattern('[0-9 +()\-]*'),
        Validators.minLength(10),
        Validators.maxLength(20)
      ]),
      'email': new FormControl(this.formData.email, [
        Validators.required,
        Validators.email,
        Validators.minLength(4),
        Validators.maxLength(100)
      ]),
      'captcha': new FormControl(this.formData.captcha, [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(8)
      ]),
      'formID': new FormControl(this.formData.formID)
    }, { updateOn: 'change' });

    this.refreshCaptcha();
  }

  offerChanged(e) {
    this.offerDisabled = !e.target.checked;
  }

  refreshCaptcha() {
    this.request.HttpGet<any>(`api/image/captcha/${this.formData.formID}`).subscribe((data: any) => {
      this.img64 = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64, ' + data);
    });
  }

  onCaptchaRefresh(e) {
    e.preventDefault();
    this.refreshCaptcha();
  }

  private updateAppsInBasket() {
    this.appService.apps.subscribe((data: any[]) => {
      const ids = this.basketService.GetAppIds();
      this.apps = data.filter(m => ids.indexOf(m.id) !== -1);
    });
  }

  addToBasket(e: any, appId: number, quantity: number) {
    e.preventDefault();

    if (quantity > 0) {
      this.basketService.Add(appId, quantity);
    } else {
      this.basketService.Remove(appId, -quantity);
    }

    this.updateAppsInBasket();
  }

  showDetails(e: any, appId: number) {
    e.preventDefault();

    const app = this.apps.find(m => m.id === appId);
    if (app != null) {
      this.appDetails = JSON.parse(JSON.stringify(app));
    }

    this.appService.appDetails(appId).subscribe(details => {
      if (details) {
        this.appDetails.coverUrl = details.coverUrl;
        this.appDetails.htmlDescription = details.htmlDescription;
      }
    });

    this.detailsModal.show();
  }

  showOffer(e: any) {
    e.preventDefault();

    this.offerModal.show();
  }

  GetSum(): number {
    let sum = 0;
    this.apps.forEach(m => sum += this.basketService.GetLicenceById(m.id) * m.price);

    return sum;
  }

  GetQuantity(): number {
    let quantity = 0;
    this.apps.forEach(m => quantity += this.basketService.GetLicenceById(m.id));

    return quantity;

  }

  startOrder(): void {
    this.refreshCaptcha();
    this.customerForm.get('captcha').reset();

    this.customerModal.show();
  }

  onSubmit() {
    const order: any = this.customerForm.value;
    order.apps = this.basketService.GetOrderApps();

    this.request
      .HttpPut<any>('api/liqpay/1', order, true)
      .subscribe(r => {
        if (!r.messages || !r.messages.length) {
          this.customerModal.hide();
          this.customerForm.reset();

          this.payData = r.data;
          this.paySignature = r.signature;

          this.toastr.success('Ваше повідомлення відправлено!', 'Успішно виконано!', {extendedTimeOut: 3000});
          this.basketService.Clean();
          this.RediarectToPaymentSystem();

          return;
        }

        this.toastr.error(r.messages[0], 'Помилка!', {extendedTimeOut: 3000});
      });
  }

  RediarectToPaymentSystem(): void {
    const paymentData: any = document.getElementById('paymentData');
    const paymentSignature: any = document.getElementById('paymentSignature');

    paymentData.value = this.payData;
    paymentSignature.value = this.paySignature;

    const paymentForm: any = document.getElementById('paymentForm');
    paymentForm.submit();
  }

}
