import { Injectable } from '@angular/core';
import { Subject, Observable, Observer } from 'rxjs';

import { RequestService } from './request.service';

@Injectable()
export class AppService {
    private dataStore: {
        appsCash: any[]
    };
    private _apps: Subject<any[]>;
  constructor(private request: RequestService) {
    this.dataStore = { appsCash: [] };
    this._apps = new Subject<any[]>();
  }

  get apps(): Observable<any[]> {
    if (!this.dataStore.appsCash || !this.dataStore.appsCash.length) {
        this.getApps();
    } else {
        setTimeout(() => this.sendApps());
    }

    return this._apps.asObservable();
  }

  public appDetails(id: number): Observable<any> {
    if (id > 0) {
      return this.request.HttpGet(`publisher/appDetails/${id}`);
    }

    return null;
  }

  private getApps(): void {
    this.request.HttpGet('publisher/apps').subscribe((data: any[]) => {
        this.dataStore.appsCash = data;
        this.sendApps();
      });
  }

  private sendApps(): void {
    this._apps.next(this.dataStore.appsCash);
  }

}
