import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

/**
 * @description
 * @class
 */
@Injectable()
export class BasketService {
  private basketKey = 'basket';

  constructor(private config: ConfigService) {}

  public GetOrderApps(): any[] {
    if (!this.config[this.basketKey]) {
      this.config[this.basketKey] = [];
    }

    return this.config[this.basketKey].map(m =>
      ({
        id: m.appId,
        quantity: m.quantity
      }));
  }

  public GetAppIds(): number[] {
    if (!this.config[this.basketKey]) {
      this.config[this.basketKey] = [];
    }

    return this.config[this.basketKey].map(m => m.appId);
  }

  public GetLicenceById(id: number): number {
    if (!this.config[this.basketKey]) {
      this.config[this.basketKey] = [];
    }

    const basketItem =  this.config[this.basketKey].find(m => m.appId === id);

    return basketItem ? basketItem.quantity : 0;
  }


  public Add(appId: number, quantity: number): void {
    if (!this.config[this.basketKey]) {
      this.config[this.basketKey] = [];
    }

    let product = this.config[this.basketKey].find((item) => item.appId === appId);

    if (product) {
      product.quantity += quantity;
    } else {
      product = { appId: appId, quantity: quantity };
      this.config[this.basketKey].push(product);
    }

    this.config.store(this.basketKey);
  }

  public Clean(): void {
    this.config[this.basketKey] = [];
    this.config.store(this.basketKey);
  }

  public Remove(appId: number, quantity: number = 0): void {
    if (!this.config[this.basketKey]) {
      this.config[this.basketKey] = [];
      return;
    }

    const product = this.config[this.basketKey].find(item => item.appId === appId);

    if (!product) {
      return;
    }

    let removeProduct = true;

    if (quantity > 0) {
      quantity = product.quantity - quantity;

      if (quantity > 0) {
        removeProduct = false;
        product.quantity = quantity;
      }
    }

    if (removeProduct) {
      this.config[this.basketKey] = this.config[this.basketKey].filter(item => item.appId !== appId);
    }

    this.config.store(this.basketKey);
  }

  public getBasketLabel(): string {
    if (!this.config[this.basketKey] || !this.config[this.basketKey].length) {
      return '';
    }

    if (this.config[this.basketKey].length > 9){
      return '9+';
    }

    return this.config[this.basketKey].length.toString();
  }

}
