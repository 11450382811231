import { Component, OnInit, ViewChild  } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { SanitizeHtmlPipe } from '../../pipes/sanitize-html-pipe';
import { AppService } from '../../services/app.service';
import { BasketService } from '../../services/basket.service';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})

export class DownloadComponent implements OnInit {
  @ViewChild('detailsModal') detailsModal;

  apps = [];
  appDetails: any = {};

  constructor(private toastr: ToastrService, private appService: AppService, private basketService: BasketService) {

  }

  ngOnInit() {
    this.appService.apps.subscribe((data: any[]) => {
      this.apps = data;
    });
  }

  addToBasket(e: any, appId: number) {
    e.preventDefault();

    this.basketService.Add(appId, 1);
    this.toastr.success('Один ключ додано у корзину.', 'Успішно виконано!');
  }

  showDetails(e: any, appId: number): void {
    e.preventDefault();

    const app = this.apps.find(m => m.id === appId);
    if (app != null) {
      this.appDetails = JSON.parse(JSON.stringify(app));
    }

    this.appService.appDetails(appId).subscribe(details => {
      if (details) {
        this.appDetails.coverUrl = details.coverUrl;
        this.appDetails.htmlDescription = details.htmlDescription;
      }
    });

    this.detailsModal.show();
  }
}
