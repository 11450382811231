import { Injectable } from '@angular/core';

@Injectable()
export class ConfigService {
  private storagePrefix = 'config_';

  public apiUrl = 'http://localhost:5000';
  public devUrl = 'http://localhost:5000';
  public errorUrl = '/session/404';
  public signinUrl = '/session/signin';
  public basket: Array<any> = [];

  constructor() {
    if (window.location.origin !== this.devUrl) {
      this.apiUrl = window.location.origin;
    }

    for (const key in this) {
      if (this.hasOwnProperty(key)) {
        this.restore(key);
      }
    }
  }

  public remove(configKey: string): void {
    localStorage.removeItem(this.storagePrefix + configKey);
  }

  public restore(configKey: string): void {
    const item = localStorage.getItem(this.storagePrefix + configKey);
    if (item) {
      this[configKey] = JSON.parse(item);
    }
  }

  public store(configKey: string): void {
    for (const key in this) {
      if (configKey === key && this.hasOwnProperty(key)) {
        localStorage.setItem(this.storagePrefix + configKey, JSON.stringify(this[key]));
        return;
      }
    }
  }

  public newGuid(): string {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }
}
