import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notfound',
  templateUrl: './notFound.component.html',
  styleUrls: ['./notFound.component.scss']
})

export class NotFoundComponent implements OnInit {

  constructor() {

  }

  ngOnInit() {

  }
}
