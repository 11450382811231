import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';

import { HomeComponent } from './home/home.component';
import { DownloadComponent } from './download/download.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ActivationComponent } from './activation/activation.component';
import { SupportComponent } from './support/support.component';
import { BasketComponent } from './basket/basket.component';
import { ErrorComponent } from './error/error.component';

import { AppService } from '../services/app.service';
import { SanitizeHtmlPipe } from '../pipes/sanitize-html-pipe';
import { OrderComponent } from './order/order.component';
import { NotFoundComponent } from './notFound/notFound.component';
import { OrderService } from '../services/order.service';
import { QuestionsComponent } from './questions/questions.component';
import { PaymentComponent } from './payment/payment.component';


const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'download', component: DownloadComponent },
  { path: 'contacts', component: ContactsComponent },
  { path: 'questions', component: QuestionsComponent },
  { path: 'payment', component: PaymentComponent },
  { path: 'support', component: SupportComponent },
  { path: 'activation', component: ActivationComponent },
  { path: 'basket', component: BasketComponent },
  { path: 'order/:showId', component: OrderComponent },
  { path: 'error', component: ErrorComponent },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  declarations: [
    HomeComponent,
    DownloadComponent,
    ContactsComponent,
    ActivationComponent,
    QuestionsComponent,
    PaymentComponent,
    SupportComponent,
    BasketComponent,
    OrderComponent,
    ErrorComponent,
    NotFoundComponent,
    SanitizeHtmlPipe
  ],
  imports: [
    ModalModule.forRoot(), ToastrModule, CommonModule, FormsModule, ReactiveFormsModule, RouterModule.forRoot(routes)
  ],
  providers: [AppService, OrderService],
  exports: [RouterModule]
})
export class AppRoutingModule { }
